import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import VeeValidatePlugin from "@/core/plugins/vee-validate";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";

/****************************************/
/********   SYNCFUSION AREA    **********/
/****************************************/
import {
  ButtonComponent,
  CheckBoxComponent,
  ChipListComponent,
  ChipsDirective,
  ChipDirective,
  RadioButtonComponent
} from "@syncfusion/ej2-vue-buttons";
import { DialogComponent } from "@syncfusion/ej2-vue-popups";
import { DropDownListComponent } from "@syncfusion/ej2-vue-dropdowns";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective
} from "@syncfusion/ej2-vue-grids";
import {
  NumericTextBoxComponent,
  TextBoxComponent
} from "@syncfusion/ej2-vue-inputs";
import {
  ToolbarComponent,
  ItemDirective,
  ItemsDirective
} from "@syncfusion/ej2-vue-navigations";
import "@mdi/font/css/materialdesignicons.css";
import {
  L10n,
  enableRipple,
  // loadCldr,
  setCulture,
  setCurrencyCode
} from "@syncfusion/ej2-base";
enableRipple(true);
/* loadCldr(
  require("cldr-data/main/it/ca-gregorian.json"),
  require("cldr-data/main/it/currencies.json"),
  require("cldr-data/main/it/numbers.json"),
  require("cldr-data/main/it/timeZoneNames.json"),
  require("cldr-data/supplemental/numberingSystems.json"),
  require("cldr-data/supplemental/weekData.json")
); */
setCulture("it-IT");
setCulture("it");
setCurrencyCode("EUR");

L10n.load({
  it: {
    datepicker: { placeholder: "gg/mm/aaaa", today: "oggi" }
  }
});
L10n.load({
  "it-IT": {
    grid: {
      EmptyRecord: "Nessun dato da visualizzare",
      True: "vero",
      False: "falso",
      InvalidFilterMessage: "Dati del filtro non validi",
      GroupDropArea:
        "Trascina qui l'intestazione di una colonna per raggrupparne la colonna",
      UnGroup: "Clicca qui per separare",
      GroupDisable: "Il raggruppamento è disabilitato per questa colonna",
      FilterbarTitle: "cella della barra dei filtri",
      EmptyDataSourceError:
        "Il DataSource non deve essere vuoto al caricamento iniziale poiché le colonne vengono generate dal dataSource nella modalità AutoGenerate Column Grid",
      Add: "Inserisci",
      Edit: "Modifica",
      Cancel: "Annulla",
      Update: "Aggiorna",
      Delete: "Elimina",
      Print: "Stampa",
      Pdfexport: "Esporta in PDF",
      Excelexport: "Esporta in Excel",
      Wordexport: "Esporta in Word",
      Csvexport: "Esporta in CSV",
      Search: "Cerca",
      Columnchooser: "Colonne",
      Save: "Salva",
      Item: "Record",
      Items: "Records",
      EditOperationAlert:
        "Nessun record selezionato per l'operazione di modifica",
      DeleteOperationAlert:
        "Nessun record selezionato per l'operazione di eliminazione",
      SaveButton: "Salva",
      OKButton: "OK",
      CancelButton: "Esci",
      EditFormTitle: "Dettagli di",
      AddFormTitle: "Aggiungi nuovo record",
      BatchSaveConfirm: "Sei sicuro di voler salvare le modifiche?",
      BatchSaveLostChanges:
        "Le modifiche non salvate andranno perse. Sei sicuro di voler continuare?",
      ConfirmDelete: "Sei sicuro di voler eliminare il record?",
      CancelEdit: "Sei sicuro di voler annullare le modifiche?",
      ChooseColumns: "Scegli Colonna",
      SearchColumns: "colonne di ricerca",
      Matchs: "Nessuna corrispondenza trovata",
      FilterButton: "Filtra",
      ClearButton: "Elimina filtro",
      StartsWith: "Inizia con",
      EndsWith: "Finisce con",
      Contains: "Contiene",
      Equal: "Uguale",
      NotEqual: "Diverso",
      LessThan: "Minore di",
      LessThanOrEqual: "Minore o Uguale di",
      GreaterThan: "Maggiore di",
      GreaterThanOrEqual: "Maggiore o Uguale di",
      ChooseDate: "Scegli una data",
      EnterValue: "Immettere il valore",
      Copy: "Copia",
      Group: "Raggruppa per questa colonna",
      Ungroup: "Separa in base a questa colonna",
      autoFitAll: "Adatta automaticamente tutte le colonne",
      autoFit: "Adatta automaticamente questa colonna",
      Export: "Esporta",
      FirstPage: "Prima Pagina",
      LastPage: "Ultima Pagina",
      PreviousPage: "Pagina precedente",
      NextPage: "Pagina successiva",
      SortAscending: "Ordine crescente",
      SortDescending: "Ordine decrescente",
      EditRecord: "Modifica record",
      DeleteRecord: "Elimina record",
      FilterMenu: "Filtro",
      SelectAll: "Seleziona tutto",
      Blanks: "Spazi vuoti",
      FilterTrue: "Vero",
      FilterFalse: "Falso",
      NoResult: "Nessun risultato trovato",
      ClearFilter: "Filtro pulito",
      NumberFilter: "Filtri numerici",
      TextFilter: "Filtri di testo",
      DateFilter: "Filtri data",
      MatchCase: "Match Case",
      Between: "Tra",
      CustomFilter: "Filtro personalizzato",
      CustomFilterPlaceHolder: "Immettere il valore",
      CustomFilterDatePlaceHolder: "Scegli una data",
      AND: "E",
      OR: "O",
      ShowRowsWhere: "Mostra righe dove:",
      currentPageInfo: "{0} di {1} pagine",
      totalItemsInfo: "({0} records)",
      firstPageTooltip: "Vai alla prima pagina",
      lastPageTooltip: "Vai all'ultima pagina",
      nextPageTooltip: "Vai alla pagina successiva",
      previousPageTooltip: "Vai alla pagina precedente",
      nextPagerTooltip: "Vai al pager successivo",
      previousPagerTooltip: "Vai al pager precedente",
      pagerDropDown: "Records per Pagina",
      pagerAllDropDown: "Records",
      All: "Tutti"
    },
    pager: {
      currentPageInfo: "{0} di {1} Pagine",
      totalItemsInfo: "({0} records)",
      firstPageTooltip: "Prima Pag.",
      lastPageTooltip: "Ultima Pag.",
      nextPageTooltip: "Pag. Succesiva",
      previousPageTooltip: "Pag. Precedente",
      nextPagerTooltip: "Pag. Successiva",
      previousPagerTooltip: "Pag. Precedente",
      pagerDropDown: "Records per Pagina",
      pagerAllDropDown: "Records",
      All: "Tutti"
    },
    toolbar: {
      Pdfexport: "Esporta in PFD",
      Excelexport: "Esporta in Excel"
    },
    uploader: {
      Browse: "Seleziona il File",
      dropFilesHint: "o Trascina qui i file",
      clear: "Pulisci tutto",
      uploadSuccessMessage: "File caricato correttamente",
      uploadFailedMessage: "Errore caricamento file",
      invalidFileType: "Tipo File non consentito",
      remove: "Rimuovi"
    },
    colorpicker: {
      Apply: "Conferma",
      Cancel: "Cancella",
      ModeSwitcher: "Modalità Switch"
    }
  }
});

import { registerLicense } from "@syncfusion/ej2-base";

// Register Syncfusion license
registerLicense(process.env.VUE_APP_APIKEY);

import "@/core/plugins/prismjs";
const app = createApp(App);

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(VeeValidatePlugin);

app.component("ejs-chiplist", ChipListComponent);
app.component("e-chips", ChipsDirective);
app.component("e-chip", ChipDirective);
app.component("ejs-radiobutton", RadioButtonComponent);
app.component("ejs-dialog", DialogComponent);
app.component("ejs-dropdownlist", DropDownListComponent);
app.component("ejs-grid", GridComponent);
app.component("e-columns", ColumnsDirective);
app.component("e-column", ColumnDirective);
app.component("ejs-numerictextbox", NumericTextBoxComponent);
app.component("ejs-textbox", TextBoxComponent);
app.component("ejs-toolbar", ToolbarComponent);
app.component("e-items", ItemsDirective);
app.component("e-item", ItemDirective);
app.component("ejs-checkbox", CheckBoxComponent);
app.component("ejs-button", ButtonComponent);

ApiService.init(app);
ApiService.errorInterceptor();
initApexCharts(app);
initInlineSvg(app);

app.use(i18n);

app.mount("#app");
