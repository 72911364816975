import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { IUserAuthenticated as User } from "@/core/data/userAuthenticated";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAdmin: boolean;
  isAuthenticated: boolean;
  tokenStart: number | null;
  tokenExpirationTime: number | null;
  apipath: string;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {};
  user = JSON.parse(localStorage.getItem("user") ?? "{}") as User;
  isAuthenticated = !!JwtService.getToken();
  isAdmin = this.user?.userName === "ADMIN" || this.user?.permessi === null;
  tokenStart = parseInt(localStorage.getItem("tokenStart") ?? "0");
  tokenExpirationTime = parseInt(
    localStorage.getItem("tokenExpirationTime") ?? "0"
  );
  apipath = localStorage.getItem("apipath") ?? process.env.VUE_APP_API_URL;

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors;
  }

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }

  /**
   * Verify if user is Admin or has Admin's permission
   * @returns boolean
   */
  get isUserAdmin(): boolean {
    return this.isAdmin;
  }

  get apiPath(): string {
    return this.apipath;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error };
  }

  @Mutation
  [Mutations.SET_APIPATH](apipath) {
    this.apipath = apipath;
    localStorage.setItem("apipath", this.apipath);
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.isAdmin = user?.userName === "ADMIN" || user?.permessi === null;
    this.errors = {};
    this.tokenStart = Date.parse(user.start_token);
    localStorage.setItem("tokenStart", this.tokenStart.toString());
    this.tokenExpirationTime = user.expiresIn * 1000;
    localStorage.setItem(
      "tokenExpirationTime",
      this.tokenExpirationTime.toString()
    );
    localStorage.setItem("user", JSON.stringify(this.user));
    JwtService.saveToken(user.token);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  /* @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  } */

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.isAdmin = false;
    localStorage.removeItem("user");
    localStorage.removeItem("tokenExpirationTime");
    localStorage.removeItem("tokenStart");
    localStorage.removeItem("apipath");
    this.user = {} as User;
    this.tokenStart = 0;
    this.tokenExpirationTime = 0;
    this.errors = [];
    this.apipath = "";
    JwtService.destroyToken();
  }

  @Action
  async [Actions.LOGIN](credentials) {
    const result = await ApiService.post("user", "login", credentials);
    if (result && !Object.keys(result.data).includes("error"))
      this.context.commit(Mutations.SET_AUTH, result.data.Data[0]);
    else this.context.commit(Mutations.SET_ERROR, [result.data.error]);
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  /* @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot_password", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
 */
  @Action
  [Actions.VERIFY_AUTH]() {
    if (
      !JwtService.getToken() ||
      this.tokenStart + this.tokenExpirationTime < Date.now()
    ) {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  /*  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.post("verify_token", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  } */
}
