import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import { rsaConsoleLog } from "../helpers/utility";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
  }

  /**
   * @description handle the axios errors
   */
  public static errorInterceptor() {
    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        rsaConsoleLog(
          `*** Result ${response.config.method?.toUpperCase()} controller: ${response.config.url
            ?.split("/")[0]
            .toUpperCase()} - data =>`,
          response
        );
        return response;
      },
      (error) => {
        const { response } = error;
        if (!response) {
          // network error
          rsaConsoleLog("----> ERROR!", error);
          Swal.fire({
            text: error,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok",
            customClass: {
              confirmButton: "btn btn-danger"
            }
          });
          return null;
        }

        if (
          [401, 403].includes(response.status) &&
          store.getters.isUserAuthenticated
        ) {
          rsaConsoleLog("----> ERROR 401 or 403! Auto Logout!", error);
          // auto logout if 401 or 403 response returned from api
          store.dispatch(Actions.LOGOUT);
          return;
        }

        const errorMessage =
          typeof response.data == "string"
            ? response.data
            : response.data?.message || response.statusText;

        rsaConsoleLog(
          `----> ERROR! controller: ${response.config?.url
            ?.split("/")[0]
            .toUpperCase()} -`,
          response
        );
        Swal.fire({
          text: "ERRORE: " + errorMessage,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "btn btn-danger"
          }
        });

        if ([401, 403].includes(response.status)) {
          response.data = { error: response.data };
          return response;
        }
        return null;
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `OAuth oauth_token= ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "application/json";
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json, text/plain, */*";
  }

  public static setSendHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `OAuth oauth_token= ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Content-Type"] =
      "application/json; charset=UTF-8";
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json";
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (slug) return ApiService.vueInstance.axios.get(`${resource}/${slug}`);
    return ApiService.vueInstance.axios.get(`${resource}`);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    slug: string,
    params: any
  ): Promise<AxiosResponse> {
    if (slug)
      return ApiService.vueInstance.axios.post(`${resource}/${slug}`, params);
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: any
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    slug: string,
    params: any
  ): Promise<AxiosResponse> {
    if (slug)
      return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    return ApiService.vueInstance.axios.put(`${resource}`, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(
    resource: string,
    slug = "" as string
  ): Promise<AxiosResponse> {
    if (slug) return ApiService.vueInstance.axios.delete(`${resource}/${slug}`);
    return ApiService.vueInstance.axios.delete(`${resource}`);
  }
}

export default ApiService;
